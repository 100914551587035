var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "designer-ghost-item", style: _vm.styles },
    [
      _c("IconAddFilter", { attrs: { "fill-color": _vm.whiteColor } }),
      _vm._v("\n    ADD ITEM\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }