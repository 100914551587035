var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classes,
      style: _vm.styles,
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
    },
    [
      _vm._t("body", function() {
        return [
          _vm._t("prepend"),
          _vm._v(" "),
          _vm._t("description", function() {
            return [
              _c("DesignerItemDescription", {
                attrs: { title: _vm.title, subtitle: _vm.subtitle }
              })
            ]
          }),
          _vm._v(" "),
          _vm._t("append")
        ]
      }),
      _vm._v(" "),
      !_vm.disabled && _vm.menuItems.length
        ? _c("ActionIconButton", {
            class: _vm.menuItemsClasses,
            attrs: {
              theme: _vm.secondaryTheme,
              size: _vm.tinySize,
              floating: _vm.menuPosition,
              options: _vm.menuItems
            },
            on: { input: _vm.onSelectValue, focus: _vm.onSelectFocus },
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function(ref) {
                    var color = ref.color
                    return [_c("IconDots", { attrs: { "fill-color": color } })]
                  }
                }
              ],
              null,
              false,
              786168029
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }