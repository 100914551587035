var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "designer-background-layer" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm._l(_vm.numberOfItems, function(item) {
        return [
          _vm._t("item", null, {
            column: _vm.getColumn(item - 1),
            row: _vm.getRow(item - 1)
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }