var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ResizeObserver", { on: { resize: _vm.onResize } }, [
    _c(
      "div",
      { staticClass: "designer" },
      [
        _c("DesignerLayers", {
          scopedSlots: _vm._u(
            [
              {
                key: "prependLayer",
                fn: function() {
                  return [
                    _vm._t("prependBody", null, {
                      rows: _vm.rows,
                      columns: _vm.columns,
                      layerStyle: _vm.layerStyle
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "backgroundLayer",
                fn: function() {
                  return [
                    _vm._t(
                      "backgroundBody",
                      function() {
                        return [
                          _c("DesignerBackgroundLayer", {
                            style: _vm.layerStyle,
                            attrs: { columns: _vm.columns, rows: _vm.rows },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var column = ref.column
                                    return [
                                      _vm._t(
                                        "backgroundItem",
                                        function() {
                                          return [
                                            _c("DesignerBackgroundItem", {
                                              key: column + " | " + row,
                                              attrs: {
                                                column: column,
                                                row: row,
                                                "has-right-border":
                                                  column + 1 === _vm.columns
                                              }
                                            })
                                          ]
                                        },
                                        { row: row, column: column }
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      },
                      {
                        rows: _vm.rows,
                        columns: _vm.columns,
                        layerStyle: _vm.layerStyle
                      }
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "draggableLayer",
                fn: function() {
                  return [
                    _vm._t("appendBody", null, {
                      rows: _vm.rows,
                      columns: _vm.columns,
                      layerStyle: _vm.layerStyle
                    })
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        }),
        _vm._v(" "),
        _c(
          "DesignerFooter",
          { ref: "footer" },
          [
            _c("Button", {
              attrs: {
                title: "ДОБАВИТЬ СТРОКУ В КОНЦЕ",
                theme: _vm.secondaryTheme,
                size: _vm.smallSize
              },
              nativeOn: {
                click: function($event) {
                  return _vm.onAddRow.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend",
                  fn: function(ref) {
                    var color = ref.color
                    return [_c("IconAdd", { attrs: { "fill-color": color } })]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }